<template>
  <div>
    <div class="block home_banner">
      <div v-runbannershow class="swiper" style="width: 100%; height: 36.4vw">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="width: 100vw">
            <div class="imgBox" style="position: relative; background: #000">
              <img
                style="
                  width: 100vw;
                  height: 36.4vw;
                  opacity: 0.7;
                  object-fit: cover;
                "
                :src="require('@/assets/Home/01.png')"
                alt=""
              />
              <div
                class="flex flex_d j_c_c a_i_s"
                style="
                  color: #fff;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              >
                <div class="_title" style="font-weight: 800; opacity: 0.8">
                  疾病管理与健康体检一体化开拓者
                </div>
                <!-- <div class="_desc" style="">
                            让生命信息智慧联结让生命信息智慧联结让生命信息智慧联结让生命信息智慧联结让生命信息智慧联结
                        </div> -->
              </div>
            </div>
          </div>
          <!--  -->
          <div class="swiper-slide" style="width: 100vw">
            <div class="imgBox" style="position: relative; background: #000">
              <img
                style="
                  width: 100vw;
                  height: 36.4vw;
                  opacity: 0.7;
                  object-fit: cover;
                "
                :src="require('@/assets/Home/02.png')"
                alt=""
              />
              <div
                class="flex flex_d j_c_c a_i_s"
                style="
                  color: #fff;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              >
                <div class="_title" style="font-weight: 800; opacity: 0.7">
                  让生命信息智慧联结
                </div>
                <!-- <div class="_desc" style="">
                            让生命信息智慧联结让生命信息智慧联结让生命信息智慧联结让生命信息智慧联结让生命信息智慧联结
                        </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  mounted() {
    var mySwiper = new Swiper(".swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
};
</script>

<style scoped>
.home_banner {
  overflow: hidden;
}
.home_banner .swiper-pagination-bullet {
  background: #fff !important;
}
._title {
  font-size: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 80px;

  text-align: center;
  /* margin-top: -1rem; */
  /* padding-top: 10vw; */
}
.imgBox img {
  filter: blur(4px);
}
._desc {
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 80px;
  letter-spacing: 1px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__button {
  background: #c4c4c4;
  height: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.is-active .el-carousel__button {
  background: rgb(26, 168, 88);
}
/* .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  } */
.home_banner {
  margin-top: 80px;
}
@media screen and (max-width: 1024px) {
  .swiper {
    height: 4.43rem !important;
  }
  img {
    height: 4.43rem !important;
  }
  .imgBox img {
    filter: blur(0.5px);
  }
  ._title {
    font-size: 0.55rem;
    text-align: center;
    padding: 0 20px;
    line-height: 0.7rem;
    margin: 0 !important;
    margin-top: 0.7rem !important;
  }
  ._desc {
    font-size: 0.28rem;
    text-align: center;
    padding: 0 10px;
  }
  .home_banner {
    margin-top: 0px;
  }
}
</style>
