<template>
  <div class="box">
    <div class="is_left_label flex a_i_c" v-runshow>
      <svg
        width="20"
        height="20"
        viewBox="0 0 39 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.03315 0H0L10.8564 10.8564C12.0994 12.0994 14.0055 12.0994 15.2486 10.8564L17.5691 8.53591L9.03315 0Z"
          fill="#45AD5C"
        />
        <path
          d="M0.248535 29.8339H9.28168L17.569 21.4637L15.2485 19.1433C14.0054 17.9002 12.0994 17.9002 10.8563 19.1433L0.248535 29.8339Z"
          fill="#45AD5C"
        />
        <path
          d="M38.9503 0H29.9171L19.4751 10.5249C16.9889 13.011 16.9889 17.0718 19.4751 19.558L29.7514 29.8343H38.7845L25.0276 15.9945C24.4475 15.4144 24.4475 14.5856 25.0276 14.0055L38.9503 0Z"
          fill="#21252E"
        />
      </svg>
      <div>生态招募</div>
    </div>
    <div>
      <div class="flex flex_d j_c_ a_i_c" v-runshow>
        <div class="title">生态、渠道的等级</div>
        <div class="tips"></div>
      </div>
      <div class="wrap" v-runshow>
        <div
          v-runcentershow
          class="item_wrap flex flex_d j_c_c a_i_c"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="is_icon" v-html="item.icon" style=""></div>
          <div class="name">
            {{ item.name }}
          </div>
          <div class="tips"></div>
          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
      </div>

      <!--  -->
      <div v-runshow class="wrap_mobile">
        <div
          class="is_4_com_swiper"
          style="position: relative; padding-bottom: 40px"
        >
          <div class="swiper-wrapper" style="">
            <div
              class="swiper-slide"
              v-for="(item, index) in list"
              style="width: 100vw"
              :key="index"
            >
              <div class="item_wrap flex flex_d j_c_c a_i_c">
                <div
                  class="is_icon"
                  v-html="item.icon"
                  style="width: 50px; height: 50px"
                ></div>
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="tips"></div>
                <div class="desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div
            style=""
            class="is_4_com_swiper_agination swiper-pagination"
          ></div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="flex flex_d j_c_ a_i_c" v-runshow>
      <div
        class="part_two_title"
        style="
          font-weight: 800;
          color: #888;
          margin-top: 100px;
          font-size: 0.2rem;
        "
      >
        合作共赢
      </div>
      <div class="title part_two_sub">我们对生态伙伴的赋能</div>
      <div class="tips"></div>
    </div>
    <div class="card_box" v-runshow>
      <div
        v-runcentershow
        class="item_card"
        v-for="(item, index) in card"
        :key="index"
      >
        <div class="card_title">
          {{ item.title }}
        </div>
        <div class="card_desc">
          {{ item.desc }}
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: [
        {
          title: "海纳计划训练营",
          desc: "产品沉浸式学习、案例多维度讲解、全场景模拟演练",
        },
        {
          title: "专项活动指导",
          desc: "制定区域专项事项（场景式推介会、品鉴会、技术沙龙等）",
        },
        {
          title: "销售队伍",
          desc: "协助下游伙伴建立专业销售队伍",
        },
        {
          title: "商机挖掘",
          desc: "共同挖掘商业机会，联合制定商业计划",
        },
        {
          title: "资金支持",
          desc: "专项资金支持",
        },
        {
          title: "营销支持",
          desc: "全程营销工具支持",
        },
        {
          title: "招投标支持",
          desc: "招投标过程支持",
        },
        {
          title: "签约授权",
          desc: "协议证书签约，授权牌发放",
        },
      ],
      list: [
        {
          name: "战略级",
          desc: "产品整合+渠道代理型，伙伴双方的产品在技术和渠道方面均可以深度整合，形成创新型的解决方案，并通过双方的渠道相互拉动销售",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 87 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 35.4453H14.3333" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M7.50781 27.9375V42.9534" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M60.7457 42.2695L45.7298 56.6029L31.3965 42.2695" stroke="#1AA858" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M7.50781 22.4763C7.50781 19.7462 7.50781 18.3811 7.50781 18.3811C34.1269 17.6986 45.73 3.36523 45.73 3.36523C45.73 3.36523 57.3332 17.6986 84.6348 18.3811C84.6348 18.3811 84.6348 46.3652 84.6348 54.5557C84.6348 62.7462 70.984 90.7303 45.73 95.5081C20.4761 90.7303 7.50781 62.7462 7.50781 54.5557C7.50781 51.8255 7.50781 50.4605 7.50781 49.0954" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "钻石级",
          desc: "渠道代理,按套或者按年销售额签订协议",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 99 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.2246 9.27148H48.6413" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M41.6133 2.24414V15.6608" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M28.8333 9.27148H21.8056L2 36.1048L49.2778 92.327L96.5556 36.1048L76.75 9.27148H54.3889" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M62.6968 34.1875L49.2802 47.6042L35.2246 34.1875" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "白金级",
          desc: "渠道代理,一单一策",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 92 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.2012 6.64453H43.8857" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M37.2422 0V12.6846" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M58.9868 33.2207L46.3023 45.9053L33.0137 33.2207" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M51.1352 6.64453H72.8801L89.7929 73.0875H2.20898L19.7258 6.64453H25.162" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
      ],
    };
  },
  mounted() {
    var mySwiper = new Swiper(".is_4_com_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".is_4_com_swiper_agination",
      },
    });
  },
};
</script>

<style scoped>
.is_icon {
  width: 0.9rem;
  height: 0.9rem;
}
.clear {
  clear: both;
}
.card_box {
  margin-top: 40px;
  /* margin-bottom: 60px; */
}
.item_card {
  float: left;
  /* width: calc(25% - 12px); */
  width: 3.73rem;
  height: 1.94rem;
  margin: 0.06rem;
  background: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 30px;
  /* height: 140px; */
  transition: all 1s ease !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item_card:hover {
  background: #1aa858;
  color: #fff;
  cursor: pointer;
}
.item_card:hover .card_title {
  color: #fff;
}
.item_card:hover .card_desc {
  color: #fff;
}
.card_title {
  text-align: center;
  font-size: 0.29rem;
  font-weight: 800;
  margin-bottom: 10px;
  color: #444;
}
.card_desc {
  font-size: 0.18rem;
  color: #666;
  text-align: center;
  line-height: 0.3rem;
}
.wrap {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 60px;
}
.wrap_mobile {
  height: 0px;
  overflow: hidden;
}
.item_wrap {
  margin: 0 20px;
}
.name {
  font-size: 0.28rem;
  line-height: 0.39rem;
  font-weight: 800;
  color: #000;
  margin-top: 20px;
  margin-bottom: 4px;
}
.desc {
  text-align: center;
  font-size: 0.18rem;
  color: #666;
  margin-top: 20px;
  letter-spacing: 1px;
  width: 3.84rem;
}
.title {
  font-size: 0.38rem;
  letter-spacing: 2px;
  margin: 16px 0;
  color: #21252e;
}
.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
}
.box {
  padding: 50px 1.9rem;
}
.is_left_label {
  font-size: 0.28rem;
}
.is_left_label svg {
  width: 0.28rem !important;
  height: 0.28rem !important;
}
.is_left_label div {
  margin-left: 10px;
}

.wrap_mobile {
  padding-top: 40px;
}
.is_4_com_swiper-pagination >>> .swiper-pagination-bullet {
  background: #1aa858;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 20px;
  }
  .wrap {
    display: none;
  }
  .wrap_mobile {
    height: auto;
    overflow: visible;
  }
  .part_two_title {
    font-size: 0.48rem !important;
    line-height: 0.67rem;
    margin-top: 1.86rem !important;
  }
  .item_card {
    width: 47.8%;
    margin: 1%;
    padding: 20px 10px;
    height: 120px;
  }
  .card_title {
    font-size: 16px;
  }
  .card_desc {
    font-size: 0.3rem;
    line-height: 54px;
  }
  .is_left_label {
    font-size: 0.36rem;
    line-height: 0.5rem;
    margin-top: 0.6rem;
  }
  .is_left_label svg {
    width: 0.36rem !important;
    height: 0.36rem !important;
  }
  .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    margin-top: 1rem;
  }
  .desc {
    width: 6rem !important;
    margin-top: 0.56rem;
  }
  .item_wrap .tips {
    width: 0.84rem;
    height: 0.03rem;
  }
  .is_icon {
    width: 1.52rem !important;
    height: 1.52rem !important;
  }
  .name {
    font-size: 0.48rem;
    line-height: 0.67rem;
  }
  .part_two_sub {
    font-size: 0.48rem;
    line-height: 0.67rem;
    margin-top: 0.16rem;
  }
  .card_box {
    margin-top: 1rem;
  }
  .card_title {
    font-size: 0.32rem;
    line-height: 0.45rem;
  }
  .card_desc {
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
}
</style>