<template>
  <div class="">
    <isHeader ref="isHeader" :theme_solid="true" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <isBanner />
      <is2Com v-runshow />
      <is3Com v-runshow />
      <is3ComMobile v-runshow />
      <is4Com />
      <NewsList />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import isBanner from "@/components/Home/banner.vue";
import is2Com from "@/components/Home/is_2_com.vue";
import is3Com from "@/components/Home/is_3_com.vue";
import is3ComMobile from "@/components/Home/is_3_com_mobile.vue";
import is4Com from "@/components/Home/is_4_com.vue";
import NewsList from "@/components/Home/NewsList.vue";

// @ is an alias to /src
export default {
  name: "Home",
  components: {
    isHeader,
    isFooter,
    isBanner,
    is2Com,
    is3Com,
    is3ComMobile,
    is4Com,
    NewsList,
  },
  data() {
    return {};
  },
  created() {
    // $.ajax({
    //   type: "POST",
    //   url: "//122.96.151.110:9875/wxPublish/getPublishList",
    //   contentType: "application/json",
    //   dataType: "json",
    //   data: JSON.stringify({
    //     offset: "0",
    //     count: "20",
    //     no_content: "0",
    //   }),
    //   success: (msg) => {
    //     console.log(msg);
    //   },
    // });
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
  methods: {},
};
</script>
