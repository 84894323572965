<template>
  <div class="p_r box" style="">
    <div class="p_a bg" v-runleftshow></div>
    <!--  -->
    <div class="p_r ctx">
      <div class="flex a_i_c j_c_b flex_wrap">
        <div v-runleftshow style="width: 40%" class="is_left">
          <div class="is_left_label flex a_i_c">
            <svg
              width="20"
              height="20"
              viewBox="0 0 39 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.03315 0H0L10.8564 10.8564C12.0994 12.0994 14.0055 12.0994 15.2486 10.8564L17.5691 8.53591L9.03315 0Z"
                fill="#45AD5C"
              />
              <path
                d="M0.248535 29.8339H9.28168L17.569 21.4637L15.2485 19.1433C14.0054 17.9002 12.0994 17.9002 10.8563 19.1433L0.248535 29.8339Z"
                fill="#45AD5C"
              />
              <path
                d="M38.9503 0H29.9171L19.4751 10.5249C16.9889 13.011 16.9889 17.0718 19.4751 19.558L29.7514 29.8343H38.7845L25.0276 15.9945C24.4475 15.4144 24.4475 14.5856 25.0276 14.0055L38.9503 0Z"
                fill="#21252E"
              />
            </svg>
            <div>产品与服务</div>
          </div>
          <div class="is_left_text">全程健康精细化管理专家</div>
          <div class="is_left_text">一体化智慧解决方案构建</div>
        </div>
        <div style="width: 50%" class="is_right_txt">
          <div v-runrightshow class="text">
            依托互联网、物联网、人工智能等技术，为受检者及慢病患者提供全方位、多层次、定制化的精细化服务，构建全程健康管理一体化平台。
          </div>
          <div v-runrightshow class="flex a_i_c j_c_b number_box">
            <div>
              <div>就医体验</div>
              <div class="flex a_i_e">
                <div class="number_">
                  <ICountUp
                    :delay="delay"
                    :endVal="81"
                    :options="options"
                    @ready="onReady"
                  />%
                </div>
                <span class="svg">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 23 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 19.7958L4.95054 13.7824L10.2701 22.6927L10.3623 23L19.7714 8.99809L23 13.5191V0L13.4679 0.0877863L16.6658 4.60878L10.7313 13.4313L5.78075 5.1355L5.56551 4.78435L0 11.7634V19.7958Z"
                      fill="#1AA858"
                    /></svg
                ></span>
              </div>
            </div>
            <div>
              <div>医生服务效率</div>
              <div class="flex a_i_e">
                <div class="number_">
                  <ICountUp
                    :delay="delay"
                    :endVal="35"
                    :options="options"
                    @ready="onReady"
                  />%
                </div>
                <span class="svg">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 23 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 19.7958L4.95054 13.7824L10.2701 22.6927L10.3623 23L19.7714 8.99809L23 13.5191V0L13.4679 0.0877863L16.6658 4.60878L10.7313 13.4313L5.78075 5.1355L5.56551 4.78435L0 11.7634V19.7958Z"
                      fill="#1AA858"
                    /></svg
                ></span>
              </div>
            </div>
            <div>
              <div>疾病治愈率</div>
              <div class="flex a_i_e">
                <div class="number_">
                  <ICountUp
                    :delay="delay"
                    :endVal="14"
                    :options="options"
                    @ready="onReady"
                  />%
                </div>
                <span class="svg">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 23 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 19.7958L4.95054 13.7824L10.2701 22.6927L10.3623 23L19.7714 8.99809L23 13.5191V0L13.4679 0.0877863L16.6658 4.60878L10.7313 13.4313L5.78075 5.1355L5.56551 4.78435L0 11.7634V19.7958Z"
                      fill="#1AA858"
                    /></svg
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex j_c_c">
        <!-- pc -->
        <div class="pc_">
          <div
            v-runcentershow
            class="item_nav flex flex_d a_i_c j_c_c"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="active_icon" v-html="item.active_icon" style=""></div>
            <div class="default_icon" v-html="item.icon" style=""></div>
            <div class="icon_desc" style="margin-top: 20px">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- mobile轮播图,废弃 -->
        <div class="mobile_">
          <div class="block">
            <div class="isswiper" style="height: calc((100vw) / 3 + 20px)">
              <div class="swiper-wrapper" style="height: 100%">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in list.length % 3 == 0
                    ? list.length / 3
                    : parseInt(list.length / 3) + 1"
                  style="width: 100vw"
                  :key="item"
                >
                  <div class="flex j_c_c">
                    <div v-for="(item_, index_) in list" :key="index_">
                      <div
                        class="mobile_item_"
                        v-if="index_ > index * 3 - 1 && index_ < index * 3 + 3"
                      >
                        <div class="mobile_item_nav flex flex_d a_i_c j_c_c">
                          <div
                            class="mobile_icon"
                            v-html="item_.icon"
                            style="width: 50%; height: 50%"
                          ></div>
                          <div style="margin-top: 10px; font-size: 12px">
                            {{ item_.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 如果需要分页器 -->
              <div class="isswiper-pagination swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
export default {
  components: {
    ICountUp,
  },
  data() {
    return {
      delay: 700,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      list: [
        {
          name: "智慧慢病管理平台",
          icon: `<svg style="width:100%;height:100%" viewBox="0 0 66 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47.5532 42.4727H17.283V60.8127H47.5532V42.4727Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M25.1967 42.4728V38.3281H39.6393V42.4728" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M50.026 43.6123H51.7077C57.742 43.1979 62.5891 37.9134 62.5891 31.4892C62.5891 26.1012 59.3247 21.6457 54.7743 19.9878C54.8732 19.3661 54.8732 18.6408 54.8732 18.0191C54.8732 9.41895 48.2454 2.37305 39.9359 2.37305C33.506 2.37305 28.1642 6.62131 25.9879 12.5274C24.9987 12.113 23.8116 11.8021 22.6246 11.8021C17.6784 11.8021 13.6226 16.0504 13.6226 21.2312C13.6226 22.0601 13.7215 22.8891 13.9194 23.6144C13.2269 23.4072 12.5345 23.4072 11.842 23.4072C6.50022 23.4072 2.14764 27.9663 2.14764 33.5616C2.14764 38.9496 6.20346 43.4051 11.3474 43.6123H14.6118" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M43.6951 19.8861C45.1156 19.8861 46.2671 18.6799 46.2671 17.1921C46.2671 15.7042 45.1156 14.498 43.6951 14.498C42.2746 14.498 41.1231 15.7042 41.1231 17.1921C41.1231 18.6799 42.2746 19.8861 43.6951 19.8861Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M30.6373 18.2279C32.0578 18.2279 33.2093 17.0217 33.2093 15.5339C33.2093 14.046 32.0578 12.8398 30.6373 12.8398C29.2169 12.8398 28.0654 14.046 28.0654 15.5339C28.0654 17.0217 29.2169 18.2279 30.6373 18.2279Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M23.9106 25.3783C25.3311 25.3783 26.4826 24.1721 26.4826 22.6843C26.4826 21.1964 25.3311 19.9902 23.9106 19.9902C22.4901 19.9902 21.3386 21.1964 21.3386 22.6843C21.3386 24.1721 22.4901 25.3783 23.9106 25.3783Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.0673 25.8959C38.4878 25.8959 39.6393 24.6897 39.6393 23.2018C39.6393 21.714 38.4878 20.5078 37.0673 20.5078C35.6469 20.5078 34.4954 21.714 34.4954 23.2018C34.4954 24.6897 35.6469 25.8959 37.0673 25.8959Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M23.9106 25.3789V28.3838L32.6157 34.3935V36.8803" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M43.695 19.8867V25.8965L32.7146 34.1858" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.0672 25.8965V30.7664" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M30.6374 18.332V32.9419" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.2633 51.6953H36.5728" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M32.4181 47.3438V56.0475" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.2633 51.6953H36.5728" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M32.4181 47.3438V56.0475" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.2633 51.6953H36.5728" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M32.4181 47.3438V56.0475" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          active_icon: `<svg style="width:100%;height:100%" viewBox="0 0 80 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1379_36944)">
                    <path d="M59.7419 50.709H20.2581V73.5477H59.7419V50.709Z" fill="white"/>
                    <path d="M30.5806 50.7101V45.5488H49.4193V50.7101" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M62.9677 52.1283H65.1613C73.0323 51.6121 79.3548 45.0315 79.3548 37.0315C79.3548 30.3218 75.0968 24.7734 69.1613 22.7089C69.2903 21.9347 69.2903 21.0315 69.2903 20.2573C69.2903 9.54763 60.6452 0.773438 49.8064 0.773438C41.4193 0.773438 34.4516 6.06376 31.6129 13.4186C30.3226 12.9025 28.7742 12.5154 27.2258 12.5154C20.7742 12.5154 15.4839 17.8057 15.4839 24.2573C15.4839 25.2896 15.6129 26.3218 15.871 27.225C14.9677 26.967 14.0645 26.967 13.1613 26.967C6.19353 26.967 0.516113 32.6444 0.516113 39.6121C0.516113 46.3218 5.80644 51.8702 12.5161 52.1283H16.7742" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7097 22.5808C56.5625 22.5808 58.0645 21.0788 58.0645 19.2259C58.0645 17.3731 56.5625 15.8711 54.7097 15.8711C52.8569 15.8711 51.3549 17.3731 51.3549 19.2259C51.3549 21.0788 52.8569 22.5808 54.7097 22.5808Z" fill="white"/>
                    <path d="M37.6775 20.5163C39.5303 20.5163 41.0323 19.0143 41.0323 17.1615C41.0323 15.3087 39.5303 13.8066 37.6775 13.8066C35.8246 13.8066 34.3226 15.3087 34.3226 17.1615C34.3226 19.0143 35.8246 20.5163 37.6775 20.5163Z" fill="white"/>
                    <path d="M28.9032 29.4187C30.756 29.4187 32.258 27.9166 32.258 26.0638C32.258 24.211 30.756 22.709 28.9032 22.709C27.0504 22.709 25.5483 24.211 25.5483 26.0638C25.5483 27.9166 27.0504 29.4187 28.9032 29.4187Z" fill="white"/>
                    <path d="M46.0646 30.0632C47.9174 30.0632 49.4194 28.5612 49.4194 26.7084C49.4194 24.8555 47.9174 23.3535 46.0646 23.3535C44.2117 23.3535 42.7097 24.8555 42.7097 26.7084C42.7097 28.5612 44.2117 30.0632 46.0646 30.0632Z" fill="white"/>
                    <path d="M28.9032 29.418V33.1599L40.258 40.6438V43.7406" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7095 22.5801V30.0639L40.387 40.3865" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0645 30.0645V36.129" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6775 20.6445V38.8381" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M34.5806 62.1934H45.4193" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M40 56.7734V67.6122" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M34.5806 62.1934H45.4193" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M40 56.7734V67.6122" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M34.5806 62.1934H45.4193" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M40 56.7734V67.6122" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1379_36944">
                    <rect width="80" height="74.1936" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
        },
        {
          name: "智慧体检系统",
          active_icon: `
                    <svg style="width:100%;height:100%" viewBox="0 0 82 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M37.5716 8.00977L9.3811 24.3145" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M72.1618 24.0098L44.4285 8.00977" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M76.1238 63.0199V29.6484" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M44.4285 84.961L72.6189 68.6562" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M9.68579 68.8086L37.1144 84.6562" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M6.02856 30.7148V62.5625" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 10.2951C43.4327 10.2951 45.3429 8.3848 45.3429 6.02839C45.3429 3.67197 43.4327 1.76172 41.0762 1.76172C38.7198 1.76172 36.8096 3.67197 36.8096 6.02839C36.8096 8.3848 38.7198 10.2951 41.0762 10.2951Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 91.2091C43.4327 91.2091 45.3429 89.2989 45.3429 86.9424C45.3429 84.586 43.4327 82.6758 41.0762 82.6758C38.7198 82.6758 36.8096 84.586 36.8096 86.9424C36.8096 89.2989 38.7198 91.2091 41.0762 91.2091Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M77.9759 29.3277C79.9996 28.1203 80.6613 25.501 79.4539 23.4773C78.2464 21.4536 75.6271 20.7919 73.6034 21.9993C71.5797 23.2067 70.918 25.8261 72.1254 27.8498C73.3329 29.8734 75.9522 30.5352 77.9759 29.3277Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M8.472 70.7711C10.4957 69.5637 11.1574 66.9443 9.94997 64.9206C8.74254 62.897 6.1232 62.2352 4.09952 63.4427C2.07583 64.6501 1.41412 67.2694 2.62154 69.2931C3.82897 71.3168 6.44831 71.9785 8.472 70.7711Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M79.7292 68.985C80.9114 66.9467 80.2174 64.336 78.1791 63.1538C76.1408 61.9716 73.5301 62.6656 72.3479 64.7039C71.1656 66.7422 71.8596 69.3529 73.8979 70.5351C75.9362 71.7173 78.547 71.0233 79.7292 68.985Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M9.68379 28.2741C10.866 26.2358 10.172 23.6251 8.13371 22.4428C6.09541 21.2606 3.48467 21.9546 2.30245 23.9929C1.12024 26.0312 1.81423 28.642 3.85253 29.8242C5.89083 31.0064 8.50157 30.3124 9.68379 28.2741Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 54.6387V82.6768" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 10.2949V37.8759" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M33.9143 50.6758L9.83813 64.9996" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M72.1618 27.9707L48.238 42.1421" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M33.4573 42.142L9.53345 28.4277" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M72.4666 64.5425L48.238 50.5234" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0763 54.6389C45.7049 54.6389 49.4572 50.8866 49.4572 46.2579C49.4572 41.6292 45.7049 37.877 41.0763 37.877C36.4476 37.877 32.6953 41.6292 32.6953 46.2579C32.6953 50.8866 36.4476 54.6389 41.0763 54.6389Z" fill="white"/>
                    <path d="M41.0762 41.6855V48.0855" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 49.1523V50.6762" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
          icon: `<svg style="width:100%;height:100%" viewBox="0 0 82 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M37.5716 8.00977L9.3811 24.3145" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M72.1618 24.0098L44.4285 8.00977" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M76.1238 63.0199V29.6484" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M44.4285 84.961L72.6189 68.6562" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M9.68579 68.8086L37.1144 84.6562" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M6.02856 30.7148V62.5625" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 10.2951C43.4327 10.2951 45.3429 8.3848 45.3429 6.02839C45.3429 3.67197 43.4327 1.76172 41.0762 1.76172C38.7198 1.76172 36.8096 3.67197 36.8096 6.02839C36.8096 8.3848 38.7198 10.2951 41.0762 10.2951Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 91.2091C43.4327 91.2091 45.3429 89.2989 45.3429 86.9424C45.3429 84.586 43.4327 82.6758 41.0762 82.6758C38.7198 82.6758 36.8096 84.586 36.8096 86.9424C36.8096 89.2989 38.7198 91.2091 41.0762 91.2091Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M77.9759 29.3277C79.9996 28.1203 80.6613 25.501 79.4539 23.4773C78.2464 21.4536 75.6271 20.7919 73.6034 21.9993C71.5797 23.2067 70.918 25.8261 72.1254 27.8498C73.3329 29.8734 75.9522 30.5352 77.9759 29.3277Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M8.472 70.7711C10.4957 69.5637 11.1574 66.9443 9.94997 64.9206C8.74254 62.897 6.1232 62.2352 4.09952 63.4427C2.07583 64.6501 1.41412 67.2694 2.62154 69.2931C3.82897 71.3168 6.44831 71.9785 8.472 70.7711Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M79.7292 68.985C80.9114 66.9467 80.2174 64.336 78.1791 63.1538C76.1408 61.9716 73.5301 62.6656 72.3479 64.7039C71.1656 66.7422 71.8596 69.3529 73.8979 70.5351C75.9362 71.7173 78.547 71.0233 79.7292 68.985Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M9.68379 28.2741C10.866 26.2358 10.172 23.6251 8.13371 22.4428C6.09541 21.2606 3.48467 21.9546 2.30245 23.9929C1.12024 26.0312 1.81423 28.642 3.85253 29.8242C5.89083 31.0064 8.50157 30.3124 9.68379 28.2741Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 54.6387V82.6768" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 10.2949V37.8759" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M33.9143 50.6758L9.83813 64.9996" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M72.1618 27.9707L48.238 42.1421" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M33.4573 42.142L9.53345 28.4277" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M72.4666 64.5425L48.238 50.5234" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0763 54.6389C45.7049 54.6389 49.4572 50.8866 49.4572 46.2579C49.4572 41.6292 45.7049 37.877 41.0763 37.877C36.4476 37.877 32.6953 41.6292 32.6953 46.2579C32.6953 50.8866 36.4476 54.6389 41.0763 54.6389Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 41.6855V48.0855" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M41.0762 49.1523V50.6762" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "智慧随访管理平台",
          active_icon: `
                    <svg style="width:100%;height:100%;" viewBox="0 0 80 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1804_1531)">
                    <path d="M62.9677 52.1283H65.1613C73.0322 51.6121 79.3548 45.0315 79.3548 37.0315C79.3548 30.3218 75.0968 24.7734 69.1613 22.7089C69.2903 21.9347 69.2903 21.0315 69.2903 20.2573C69.2903 9.54763 60.6451 0.773438 49.8064 0.773438C41.4193 0.773438 34.4516 6.06376 31.6129 13.4186C30.3226 12.9025 28.7742 12.5154 27.2258 12.5154C20.7742 12.5154 15.4839 17.8057 15.4839 24.2573C15.4839 25.2896 15.6129 26.3218 15.871 27.225C14.9677 26.967 14.0645 26.967 13.1613 26.967C6.19353 26.967 0.516113 32.6444 0.516113 39.6121C0.516113 46.3218 5.80644 51.8702 12.5161 52.1283H16.7742" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7096 22.5808C56.5624 22.5808 58.0644 21.0788 58.0644 19.2259C58.0644 17.3731 56.5624 15.8711 54.7096 15.8711C52.8568 15.8711 51.3547 17.3731 51.3547 19.2259C51.3547 21.0788 52.8568 22.5808 54.7096 22.5808Z" fill="white"/>
                    <path d="M37.6774 20.5163C39.5302 20.5163 41.0322 19.0143 41.0322 17.1615C41.0322 15.3087 39.5302 13.8066 37.6774 13.8066C35.8245 13.8066 34.3225 15.3087 34.3225 17.1615C34.3225 19.0143 35.8245 20.5163 37.6774 20.5163Z" fill="white"/>
                    <path d="M28.9032 29.4187C30.756 29.4187 32.258 27.9167 32.258 26.0638C32.258 24.211 30.756 22.709 28.9032 22.709C27.0504 22.709 25.5483 24.211 25.5483 26.0638C25.5483 27.9167 27.0504 29.4187 28.9032 29.4187Z" fill="white"/>
                    <path d="M46.0646 30.0632C47.9174 30.0632 49.4194 28.5612 49.4194 26.7084C49.4194 24.8555 47.9174 23.3535 46.0646 23.3535C44.2117 23.3535 42.7097 24.8555 42.7097 26.7084C42.7097 28.5612 44.2117 30.0632 46.0646 30.0632Z" fill="white"/>
                    <path d="M28.9033 29.418V33.1599L40.2582 40.6438V43.7405" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7095 22.5801V30.0639L40.387 40.3865" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0645 30.0645V36.129" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6775 20.6445V38.8381" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7097 45.9355H60V68.7743H20.5161V45.9355H25.6774" fill="white"/>
                    <path d="M39.9999 68.7736C39.9999 68.7736 34.7096 65.4187 25.8064 64.9026C25.6773 55.9994 25.8064 48.5155 25.8064 42.0639C33.8064 40.2574 40.258 45.9349 40.258 45.9349" fill="white"/>
                    <path d="M39.9999 68.7736C39.9999 68.7736 34.7096 65.4187 25.8064 64.9026C25.6773 55.9994 25.8064 48.5155 25.8064 42.0639C33.8064 40.2574 40.258 45.9349 40.258 45.9349" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M40.516 67.7413C40.516 67.7413 40.387 68.7736 40.645 68.6445C41.6773 67.8703 46.9676 65.2897 54.8386 64.9026C54.9676 55.9994 54.8386 48.5155 54.8386 42.0639C46.8386 40.2574 40.387 45.9349 40.387 45.9349L40.516 67.7413Z" fill="white" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1804_1531">
                    <rect width="80" height="69.4194" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
          icon: `<svg style="width:100%;height:100%" viewBox="0 0 80 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1379_36872)">
                    <path d="M62.9677 52.1283H65.1613C73.0322 51.6121 79.3548 45.0315 79.3548 37.0315C79.3548 30.3218 75.0968 24.7734 69.1613 22.7089C69.2903 21.9347 69.2903 21.0315 69.2903 20.2573C69.2903 9.54763 60.6451 0.773438 49.8064 0.773438C41.4193 0.773438 34.4516 6.06376 31.6129 13.4186C30.3226 12.9025 28.7742 12.5154 27.2258 12.5154C20.7742 12.5154 15.4839 17.8057 15.4839 24.2573C15.4839 25.2896 15.6129 26.3218 15.871 27.225C14.9677 26.967 14.0645 26.967 13.1613 26.967C6.19353 26.967 0.516113 32.6444 0.516113 39.6121C0.516113 46.3218 5.80644 51.8702 12.5161 52.1283H16.7742" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7096 22.5808C56.5624 22.5808 58.0644 21.0788 58.0644 19.2259C58.0644 17.3731 56.5624 15.8711 54.7096 15.8711C52.8568 15.8711 51.3547 17.3731 51.3547 19.2259C51.3547 21.0788 52.8568 22.5808 54.7096 22.5808Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6774 20.5163C39.5302 20.5163 41.0322 19.0143 41.0322 17.1615C41.0322 15.3087 39.5302 13.8066 37.6774 13.8066C35.8245 13.8066 34.3225 15.3087 34.3225 17.1615C34.3225 19.0143 35.8245 20.5163 37.6774 20.5163Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.9032 29.4187C30.756 29.4187 32.258 27.9167 32.258 26.0638C32.258 24.211 30.756 22.709 28.9032 22.709C27.0504 22.709 25.5483 24.211 25.5483 26.0638C25.5483 27.9167 27.0504 29.4187 28.9032 29.4187Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0646 30.0632C47.9174 30.0632 49.4194 28.5612 49.4194 26.7084C49.4194 24.8555 47.9174 23.3535 46.0646 23.3535C44.2117 23.3535 42.7097 24.8555 42.7097 26.7084C42.7097 28.5612 44.2117 30.0632 46.0646 30.0632Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.9033 29.418V33.1599L40.2582 40.6438V43.7405" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7095 22.5801V30.0639L40.387 40.3865" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0645 30.0645V36.129" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6775 20.6445V38.8381" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7097 45.9355H60V68.7743H20.5161V45.9355H25.6774" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M39.9999 68.7736C39.9999 68.7736 34.7096 65.4187 25.8064 64.9026C25.6773 55.9994 25.8064 48.5155 25.8064 42.0639C33.8064 40.2574 40.258 45.9349 40.258 45.9349" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M40.516 67.7413C40.516 67.7413 40.387 68.7736 40.645 68.6445C41.6773 67.8703 46.9676 65.2897 54.8386 64.9026C54.9676 55.9994 54.8386 48.5155 54.8386 42.0639C46.8386 40.2574 40.387 45.9349 40.387 45.9349L40.516 67.7413Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1379_36872">
                    <rect width="80" height="69.4194" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
        },
        {
          name: "满意度调查",
          active_icon: `
                        <svg style="width:100%;height:100%" viewBox="0 0 62 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1804_1510)">
                        <path d="M5.89795 35.2368V8.01562H50.0567V41.1347" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M10.4348 8.01484V0.755859H57.6182V41.1339" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M0.756104 35.0859H18.2986L25.1039 41.1351H61.0964V79.2447H0.756104V35.0859Z" fill="white"/>
                        <path d="M7.86377 53.3848H20.567" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M14.2153 47.1836V59.7356" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M13.0059 16.332H41.4369" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M13.0059 21.9277H41.4369" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M13.0059 27.5234H41.4369" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M24.6501 33.1191H41.4365" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M37.8071 71.3796H53.2325V57.1641" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1804_1510">
                        <rect width="61.8526" height="80" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>

                    `,
          icon: `<svg style="width:100%;height:100%" viewBox="0 0 62 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1379_36851)">
                    <path d="M5.89795 35.2368V8.01562H50.0567V41.1347" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M10.4348 8.01484V0.755859H57.6182V41.1339" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M0.756104 35.0859H18.2986L25.1039 41.1351H61.0964V79.2447H0.756104V35.0859Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M7.86377 53.3848H20.567" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M14.2153 47.1836V59.7356" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M13.0059 16.332H41.4369" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M13.0059 21.9277H41.4369" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M13.0059 27.5234H41.4369" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M24.6501 33.1191H41.4365" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.8071 71.3796H53.2325V57.1641" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1379_36851">
                    <rect width="61.8526" height="80" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
        },
        {
          name: "智慧宣教管理平台",
          active_icon: `<svg style="width:100%;height:100%" viewBox="0 0 80 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1804_1547)">
                    <path d="M60.129 46.1934H20.6451V69.0321H60.129V46.1934Z" fill="white"/>
                    <path d="M17.0322 74.3223H63.8709" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M62.9677 52.1283H65.1613C73.0322 51.6122 79.3548 45.0315 79.3548 37.0315C79.3548 30.3218 75.0968 24.7734 69.1613 22.7089C69.2903 21.9347 69.2903 21.0315 69.2903 20.2573C69.2903 9.54763 60.6451 0.773438 49.8064 0.773438C41.4193 0.773438 34.4516 6.06376 31.6129 13.4186C30.3226 12.9025 28.7742 12.5154 27.2258 12.5154C20.7742 12.5154 15.4839 17.8057 15.4839 24.2573C15.4839 25.2896 15.6129 26.3218 15.871 27.2251C14.9677 26.967 14.0645 26.967 13.1613 26.967C6.19353 26.967 0.516113 32.6444 0.516113 39.6122C0.516113 46.3218 5.80644 51.8702 12.5161 52.1283H16.7742" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7096 22.5808C56.5624 22.5808 58.0644 21.0788 58.0644 19.2259C58.0644 17.3731 56.5624 15.8711 54.7096 15.8711C52.8568 15.8711 51.3547 17.3731 51.3547 19.2259C51.3547 21.0788 52.8568 22.5808 54.7096 22.5808Z" fill="white"/>
                    <path d="M37.6773 20.5163C39.5302 20.5163 41.0322 19.0143 41.0322 17.1615C41.0322 15.3087 39.5302 13.8066 37.6773 13.8066C35.8245 13.8066 34.3225 15.3087 34.3225 17.1615C34.3225 19.0143 35.8245 20.5163 37.6773 20.5163Z" fill="white"/>
                    <path d="M28.9032 29.4187C30.756 29.4187 32.258 27.9166 32.258 26.0638C32.258 24.211 30.756 22.709 28.9032 22.709C27.0504 22.709 25.5483 24.211 25.5483 26.0638C25.5483 27.9166 27.0504 29.4187 28.9032 29.4187Z" fill="white"/>
                    <path d="M46.0644 30.0632C47.9173 30.0632 49.4193 28.5612 49.4193 26.7084C49.4193 24.8555 47.9173 23.3535 46.0644 23.3535C44.2116 23.3535 42.7096 24.8555 42.7096 26.7084C42.7096 28.5612 44.2116 30.0632 46.0644 30.0632Z" fill="white"/>
                    <path d="M28.9032 29.418V33.1599L40.258 40.6438V43.7406" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7097 22.5801V30.0639L40.3871 40.3865" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0645 30.0645V36.129" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6775 20.6445V38.8381" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M26.1936 62.9674L36.0001 56.6448L41.4194 61.29L54.5807 52.3867" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1804_1547">
                    <rect width="80" height="74.9677" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
          icon: `<svg style="width:100%;height:100%" viewBox="0 0 80 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1379_36888)">
                    <path d="M60.1289 46.1934H20.645V69.0321H60.1289V46.1934Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M17.0322 74.3223H63.8709" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M62.9677 52.1283H65.1613C73.0322 51.6122 79.3548 45.0315 79.3548 37.0315C79.3548 30.3218 75.0968 24.7734 69.1613 22.7089C69.2903 21.9347 69.2903 21.0315 69.2903 20.2573C69.2903 9.54763 60.6451 0.773438 49.8064 0.773438C41.4193 0.773438 34.4516 6.06376 31.6129 13.4186C30.3226 12.9025 28.7742 12.5154 27.2258 12.5154C20.7742 12.5154 15.4839 17.8057 15.4839 24.2573C15.4839 25.2896 15.6129 26.3218 15.871 27.2251C14.9677 26.967 14.0645 26.967 13.1613 26.967C6.19353 26.967 0.516113 32.6444 0.516113 39.6122C0.516113 46.3218 5.80644 51.8702 12.5161 52.1283H16.7742" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7096 22.5808C56.5624 22.5808 58.0644 21.0788 58.0644 19.2259C58.0644 17.3731 56.5624 15.8711 54.7096 15.8711C52.8568 15.8711 51.3547 17.3731 51.3547 19.2259C51.3547 21.0788 52.8568 22.5808 54.7096 22.5808Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6773 20.5163C39.5302 20.5163 41.0322 19.0143 41.0322 17.1615C41.0322 15.3087 39.5302 13.8066 37.6773 13.8066C35.8245 13.8066 34.3225 15.3087 34.3225 17.1615C34.3225 19.0143 35.8245 20.5163 37.6773 20.5163Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.9032 29.4187C30.756 29.4187 32.258 27.9166 32.258 26.0638C32.258 24.211 30.756 22.709 28.9032 22.709C27.0504 22.709 25.5483 24.211 25.5483 26.0638C25.5483 27.9166 27.0504 29.4187 28.9032 29.4187Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0646 30.0632C47.9174 30.0632 49.4194 28.5612 49.4194 26.7084C49.4194 24.8555 47.9174 23.3535 46.0646 23.3535C44.2117 23.3535 42.7097 24.8555 42.7097 26.7084C42.7097 28.5612 44.2117 30.0632 46.0646 30.0632Z" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M28.9033 29.418V33.1599L40.2582 40.6438V43.7406" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M54.7095 22.5801V30.0639L40.387 40.3865" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M46.0645 30.0645V36.129" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M37.6775 20.6445V38.8381" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M26.1936 62.9674L36.0001 56.6448L41.4194 61.29L54.5807 52.3867" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1379_36888">
                    <rect width="80" height="74.9677" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
        },
        {
          name: "智能体测设备",
          active_icon: `
                        <svg style="width:100%;height:100%" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1804_1522)">
                        <path d="M40.0001 79.2799C61.6941 79.2799 79.2806 61.6934 79.2806 39.9993C79.2806 18.3053 61.6941 0.71875 40.0001 0.71875C18.306 0.71875 0.719482 18.3053 0.719482 39.9993C0.719482 61.6934 18.306 79.2799 40.0001 79.2799Z" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M40 40L63.1655 66.6187" stroke="white" stroke-width="1.6" stroke-miterlimit="10"/>
                        <path d="M39.9999 43.1649C41.7482 43.1649 43.1654 41.7477 43.1654 39.9995C43.1654 38.2512 41.7482 36.834 39.9999 36.834C38.2517 36.834 36.8345 38.2512 36.8345 39.9995C36.8345 41.7477 38.2517 43.1649 39.9999 43.1649Z" fill="white"/>
                        <path d="M42.3021 58.9928C41.5827 59.1367 40.8633 59.1367 40 59.1367C29.4964 59.1367 20.8633 50.6474 20.8633 40C20.8633 29.3525 29.3525 20.8633 40 20.8633C50.6475 20.8633 59.1367 29.3525 59.1367 40C59.1367 46.0431 56.4029 51.3669 52.0863 54.8201" stroke="white" stroke-miterlimit="10"/>
                        <path d="M45.6115 68.9218C43.741 69.2096 41.8705 69.4974 40 69.4974C23.741 69.4974 10.6475 56.2599 10.6475 40.1448C10.6475 24.0297 23.741 10.6484 40 10.6484C56.259 10.6484 69.3525 23.8858 69.3525 40.001C69.3525 48.9218 65.3237 56.9794 58.9928 62.447" stroke="white" stroke-miterlimit="10"/>
                        <path d="M69.5719 49.0639C69.5719 50.0592 68.765 50.866 67.7697 50.866C66.7744 50.866 65.9675 50.0592 65.9675 49.0639C65.9675 48.0686 66.7744 47.2617 67.7697 47.2617C68.765 47.2617 69.5719 48.0686 69.5719 49.0639Z" fill="#1AA858" stroke="white"/>
                        <path d="M20.5072 60.144C20.5072 61.1393 19.7003 61.9461 18.705 61.9461C17.7097 61.9461 16.9028 61.1393 16.9028 60.144C16.9028 59.1486 17.7097 58.3418 18.705 58.3418C19.7003 58.3418 20.5072 59.1486 20.5072 60.144Z" fill="#1AA858" stroke="white"/>
                        <path d="M38.6368 21.0072C38.6368 22.0025 37.8299 22.8094 36.8346 22.8094C35.8393 22.8094 35.0325 22.0025 35.0325 21.0072C35.0325 20.0119 35.8393 19.2051 36.8346 19.2051C37.8299 19.2051 38.6368 20.0119 38.6368 21.0072Z" fill="#1AA858" stroke="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1804_1522">
                        <rect width="80" height="80" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>

                    `,
          icon: `<svg style="width:100%;height:100%" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1379_36863)">
                    <path d="M40.0001 79.2799C61.6941 79.2799 79.2806 61.6934 79.2806 39.9993C79.2806 18.3053 61.6941 0.71875 40.0001 0.71875C18.306 0.71875 0.719482 18.3053 0.719482 39.9993C0.719482 61.6934 18.306 79.2799 40.0001 79.2799Z" stroke="#61646B" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M40 40L63.1655 66.6187" stroke="#1AA858" stroke-width="1.6" stroke-miterlimit="10"/>
                    <path d="M39.9999 43.1649C41.7482 43.1649 43.1654 41.7477 43.1654 39.9995C43.1654 38.2512 41.7482 36.834 39.9999 36.834C38.2517 36.834 36.8345 38.2512 36.8345 39.9995C36.8345 41.7477 38.2517 43.1649 39.9999 43.1649Z" fill="#61646B"/>
                    <path d="M67.7697 51.366C69.0411 51.366 70.0719 50.3353 70.0719 49.0639C70.0719 47.7924 69.0411 46.7617 67.7697 46.7617C66.4982 46.7617 65.4675 47.7924 65.4675 49.0639C65.4675 50.3353 66.4982 51.366 67.7697 51.366Z" fill="#1AA858"/>
                    <path d="M36.8346 23.3094C38.1061 23.3094 39.1368 22.2787 39.1368 21.0072C39.1368 19.7358 38.1061 18.7051 36.8346 18.7051C35.5632 18.7051 34.5325 19.7358 34.5325 21.0072C34.5325 22.2787 35.5632 23.3094 36.8346 23.3094Z" fill="#1AA858"/>
                    <path d="M18.705 62.4461C19.9764 62.4461 21.0072 61.4154 21.0072 60.144C21.0072 58.8725 19.9764 57.8418 18.705 57.8418C17.4335 57.8418 16.4028 58.8725 16.4028 60.144C16.4028 61.4154 17.4335 62.4461 18.705 62.4461Z" fill="#1AA858"/>
                    <path d="M45.6115 68.9218C43.741 69.2096 41.8705 69.4974 40 69.4974C23.741 69.4974 10.6475 56.2599 10.6475 40.1448C10.6475 24.0297 23.741 10.6484 40 10.6484C56.259 10.6484 69.3525 23.8858 69.3525 40.001C69.3525 48.9218 65.3237 56.9794 58.9928 62.447" stroke="#1AA858" stroke-miterlimit="10"/>
                    <path d="M42.3021 58.9928C41.5827 59.1367 40.8633 59.1367 40 59.1367C29.4964 59.1367 20.8633 50.6474 20.8633 40C20.8633 29.3525 29.3525 20.8633 40 20.8633C50.6475 20.8633 59.1367 29.3525 59.1367 40C59.1367 46.0431 56.4029 51.3669 52.0863 54.8201" stroke="#1AA858" stroke-miterlimit="10"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1379_36863">
                    <rect width="80" height="80" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `,
        },
      ],
    };
  },
  mounted() {
    var mySwiper = new Swiper(".isswiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".isswiper-pagination",
      },
    });
  },
  methods: {
    onReady: function (instance, CountUp) {
      const that = this;
      // instance.update(that.endVal + 100);
    },
  },
};
</script>

<style scoped>
.is_right_txt {
  font-size: 0.2rem !important;
}
.number_box {
  margin-top: 20px;
  color: #797979;
  font-size: 0.14rem;
}
.number_box > div {
  /* flex: 1; */
}
.number_box .svg {
  transform: translateY(0.05rem);
  width: 0.3rem;
  height: 0.3rem;
}
.number_ {
  color: #000;
  font-size: 0.3rem;
  font-weight: 900;
  margin-top: 10px;
}
.box >>> .swiper-pagination-bullet {
  background: #1aa858;
}
.pc_ {
  display: flex;
  margin-top: 60px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.06);
}
.mobile_ {
  display: none;
  padding-top: 20px;
}
.item_nav {
  /* width:calc( (100vw - 160px) / 6);
    height:calc( (100vw - 160px) / 6); */
  width: 2.62rem;
  height: 2.62rem;
  background: #fff;
  box-sizing: border-box;
  transition: all 0.5s ease !important;
}
.mobile_item_nav {
  width: calc((100vw - 50px) / 3 - 10px);
  height: calc((100vw - 50px) / 3 - 10px);
  background: #f7f7fa;
  margin: 10px 5px;
}
.item_nav:hover {
  background: #1aa858;
  color: #fff;
  cursor: pointer;
}
/* .item_nav:hover>>>path{
    fill: #fff;
} */
.default_icon,
.active_icon {
  width: 0.75rem;
  height: 0.75rem;
}
.icon_desc {
  font-size: 0.25rem;
}
.item_nav:hover .default_icon {
  display: none;
}
.item_nav:hover .icon_desc {
  color: white;
}
.active_icon {
  display: none;
}
.item_nav:hover .active_icon {
  display: inline-block;
}
/* .item_nav:nth-child(1){
    background: #1AA858;
    color: #fff;
} */

.bg {
  width: 40%;
  height: 100%;
  background: #f7f7fa;
  z-index: 1;
}
.is_left_label {
  font-size: 0.28rem;
  margin-bottom: 0.26rem;
}
.is_left_label svg {
  width: 0.28rem;
  height: 0.28rem;
}
.is_left_label div {
  margin-left: 10px;
}
.ctx {
  z-index: 2;
  padding: 50px 1.85rem;
  padding-top: 100px;
}
.box {
  padding: 50px 80px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.is_left_text {
  font-size: 0.3rem;
  line-height: 0.5rem;
  font-weight: 800;
}
.is_right_txt {
  font-size: 16px;
  color: #666;
  line-height: 200%;
}
.number_ >>> span {
  min-width: 38px;
  display: inline-block;
}
@media screen and (max-width: 1024px) {
  .is_right_txt .text {
    font-size: 0.3rem;
    line-height: 0.54rem;
    margin-top: 0.8rem;
    color: #21252e;
  }
  .is_left_label {
    font-size: 0.36rem;
    margin-top: 1rem !important;
    padding-top: 0 !important;
  }
  .is_left_label svg {
    width: 0.36rem !important;
    height: 0.36rem !important;
  }
  .number_ {
    font-size: 0.48rem;
    margin-top: 0;
  }
  .number_box {
    margin-top: 0.38rem;
    font-size: 0.24rem;
    line-height: 0.54rem;
    color: #797979;
  }
  .number_box .number_ >>> span {
    min-width: 28px;
    display: inline-block;
  }
  .number_box .svg {
    transform: translateY(0.15rem);
    width: 0.5rem;
    height: 0.5rem;
  }

  .bg {
    display: none;
  }
  .is_left {
    width: 100% !important;
  }
  .is_left_text {
    font-size: 0.3rem;
    line-height: 0.42rem;
    margin-top: 0.2rem !important;
  }
  .is_left div {
    /* margin-top:10px!important; */
  }
  .is_right_txt {
    width: 100% !important;
  }
  .box {
    padding: 0 20px;
  }
  .ctx {
    padding: 20px 0;
  }
  .pc_ {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-shadow: none;
    margin-top: 0.5rem;
    justify-content: space-between;
  }
  .pc_ > .item_nav {
    width: 2rem;
    height: 2.1rem;
    background: #f7f7fa;
    margin-bottom: 0.26rem;
  }
  .pc_ > .item_nav:hover {
    background: #1aa858;
  }
  /* .pc_ {
    display: none;
  }
  .mobile_ {
    display: inline-block;
    width: 100%;
  } */
  /* .mobile_item_nav {
    width: 1.85rem;
    height: 2.1rem;
    margin: 0.16rem;
    margin-top: 0.3rem;
  }
  .mobile_icon {
    width: 0.66rem !important;
    height: 0.66rem !important;
  } */
}
</style>
