<template>
  <div class="flex p_r box" style="width: 100vw; overflow-x: hidden">
    <div
      ref="move"
      class="move"
      :style="{ marginLeft: `calc(-50vw * ${move_size})` }"
    ></div>
    <div
      @click="jump_(item, index)"
      :class="{ istrue: current == index }"
      class="item_"
      v-for="(item, index) in list"
      :key="index"
    >
      <div style="height: 100%" class="p_r">
        <img class="bg" :src="item.bg" alt="" />
        <div class="ctx p_a">
          <svg
            width="50"
            viewBox="0 0 46 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1379_37008)">
              <path
                d="M29.8908 41.9167C27.9759 45.9091 25.3931 48.3575 22.543 48.3575C16.6648 48.3575 11.8999 37.9243 11.8999 25.0868C11.8999 19.6827 12.746 14.7198 14.1488 10.7715"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M15.3286 7.92691C17.2435 4.11096 19.7595 1.79492 22.5428 1.79492C28.421 1.79492 33.1859 12.2281 33.1859 25.0656C33.1859 30.4476 32.3398 35.4106 30.937 39.3589"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M42.8773 36.7064C45.8163 31.6637 39.0911 22.3664 27.8561 15.9404C16.621 9.5144 5.13069 8.39302 2.19168 13.4357C-0.74734 18.4785 5.97791 27.7757 17.2129 34.2017C28.448 40.6278 39.9383 41.7491 42.8773 36.7064Z"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
              />
              <path
                d="M40.9566 27.2043C43.4727 30.8438 44.3188 34.2847 42.8938 36.7331C39.9547 41.7622 28.4432 40.6373 17.2212 34.2186C12.5008 31.5275 8.58197 28.3071 5.84326 25.1309"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M3.95065 22.6833C1.5682 19.1541 0.811155 15.8013 2.19164 13.4191C5.13074 8.38999 16.6422 9.51492 27.8643 15.9337C32.5624 18.6247 36.4812 21.823 39.2199 24.9993"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M33.5868 10.3309C38.0178 9.97798 41.469 10.9706 42.894 13.419C45.8331 18.4701 39.1088 27.7784 27.8868 34.1971C23.1664 36.8881 18.4015 38.6748 14.26 39.4248"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M11.1649 39.8444C6.88988 40.1312 3.59452 39.1165 2.19177 36.7343C-0.747334 31.6831 5.97698 22.3749 17.199 15.9561C21.8971 13.2651 26.662 11.5005 30.8035 10.7285"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M20.2939 25.0664H24.7917"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M22.5427 22.8379V27.3156"
                stroke="white"
                stroke-width="1.6"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1379_37008">
                <rect width="45.2381" height="50" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div class="title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <div @click="toleft()" class="toleft flex j_c_c a_i_c">
      <svg
        width="13"
        height="22"
        viewBox="0 0 13 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6066 0.000820339L0 10.6074L10.6066 21.214L12.7279 19.0927L4.24268 10.6074L12.7279 2.12216L10.6066 0.000820339Z"
          fill="white"
        />
      </svg>
    </div>
    <div @click="toright()" class="toright flex j_c_c a_i_c">
      <svg
        width="14"
        height="22"
        viewBox="0 0 14 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.60653 21.214L13.2131 10.6074L2.60653 0.000820339L0.485195 2.12216L8.97046 10.6074L0.485195 19.0927L2.60653 21.214Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item_width: 0,
      move_size: 0,
      current: 0,
      list: [
        {
          bg: require("@/assets/Home/is_3_com/01.png"),
          title: "健康管理中心解决方案",
          desc: "以“医防融合”为着力点，依托互联网、物联网、人工智能等技术，为慢病患者提供全方位、多层次、定制化的全程精细化服务，打造慢病管理全周期体系。",
          id: "",
          path: "healthyManageCore",
        },
        {
          bg: require("@/assets/Home/is_3_com/02.png"),
          title: "慢病管理中心解决方案",
          desc: "以“医防融合”为着力点，依托互联网、物联网、人工智能等技术，为慢病患者提供全方位、多层次、定制化的全程精细化服务，打造慢病管理全周期体系。",
          id: "",
          path: "slowSickManageCore",
        },
        {
          bg: require("@/assets/Home/is_3_com/03.png"),
          title: "随访解决方案",
          desc: "以“医防融合”为着力点，依托互联网、物联网、人工智能等技术，为慢病患者提供全方位、多层次、定制化的全程精细化服务，打造慢病管理全周期体系。",
          id: "",
          path: "hosFollowUp",
        },
        {
          bg: require("@/assets/Home/is_3_com/04.png"),
          title: "区域健康宣教解决方案",
          desc: "以“医防融合”为着力点，依托互联网、物联网、人工智能等技术，为慢病患者提供全方位、多层次、定制化的全程精细化服务，打造慢病管理全周期体系。",
          id: "",
          path: "regionEdu",
        },
      ],
    };
  },
  mounted() {
    //   var mySwiper = new Swiper ('.is_3_swiper', {
    //   direction: 'horizontal', // 垂直切换选项
    //   loop: true, // 循环模式选项
    //   // 如果需要分页器
    //   pagination: {
    //     el: '.is3swiper-pagination',
    //   },
    // })
  },
  methods: {
    toleft() {
      if (this.move_size > 0) {
        let bodywidth = document.getElementsByTagName("body")[0].offsetWidth;
        this.item_width = bodywidth * 0.5;
        this.move_size--;
        this.$forceUpdate();
      }
    },
    toright() {
      if (this.list.length > this.move_size + 2) {
        let bodywidth = document.getElementsByTagName("body")[0].offsetWidth;
        this.item_width = bodywidth * 0.5;
        this.move_size++;
        this.$forceUpdate();
      }
    },
    jump_(item, index) {
      // console.log(item,index)
      this.$router.push({
        path: "/" + item.path,
      });
    },
    onMouseenter(index, e) {
      this.current = index;
    },
  },
};
</script>

<style scoped>
.move {
  transition: all 0.8s ease;
}
.box {
  display: none;
}
.toleft {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.7);
}
.toright {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.7);
}
.toleft:hover {
  background: #1aa858;
}
.toright:hover {
  background: #1aa858;
}
.btn {
  display: inline-block;
  background: #1aa858;
  color: #fff;
  font-size: 12px;
  padding: 4px 16px;
  margin-top: 10px;
  cursor: pointer;
}
.info_title {
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 10px;
}
.info_desc {
  font-size: 12px;
  line-height: 180%;
}
@keyframes ctx_detail_pro {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.ctx_detail_pro {
  background: rgba(247, 247, 250, 0.9);
  color: #000;
  padding: 20px;
  box-sizing: border-box;
  animation: ctx_detail_pro 0.8s ease;
}
.ctx_detail {
  display: none;
  width: 100vw;
  left: 0;
  bottom: 0;
  widows: 100%;
  box-sizing: border-box;
}
.ctx {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
}
.item_ {
  transition: all 0.4s;
  flex: none;
  width: 50vw;
  height: 80vw;
  background: #0c141f;
  overflow-x: hidden;
}
.bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}
.istrue {
  /* width: 100vw; */
  height: 80vw;
  flex: none;
  background: #0c141f;
}
/* .istrue .ctx{
  display: none;
} */
.ctx_detail {
  display: none;
}
/* .istrue .ctx_detail{
  display: inline-block;
} */
.base {
  width: 50vw;
  height: 30vw;
  background: #0c141f;
}

@media screen and (max-width: 1024px) {
  .box {
    padding: 0;
    display: flex;
  }
  .ctx svg {
    width: 0.52rem !important;
    height: 0.52rem !important;
  }
  .title {
    font-size: 0.36rem;
    line-height: 0.5rem;
    margin-top: 0.1rem;
  }
}
</style>