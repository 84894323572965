<template>
  <div class="NewsListView" ref="">
    <div class="is_left_label flex a_i_c j_s" v-runshow>
      <div class="flex a_i_c">
        <svg
          width="20"
          height="20"
          viewBox="0 0 39 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.03315 0H0L10.8564 10.8564C12.0994 12.0994 14.0055 12.0994 15.2486 10.8564L17.5691 8.53591L9.03315 0Z"
            fill="#45AD5C"
          />
          <path
            d="M0.248535 29.8339H9.28168L17.569 21.4637L15.2485 19.1433C14.0054 17.9002 12.0994 17.9002 10.8563 19.1433L0.248535 29.8339Z"
            fill="#45AD5C"
          />
          <path
            d="M38.9503 0H29.9171L19.4751 10.5249C16.9889 13.011 16.9889 17.0718 19.4751 19.558L29.7514 29.8343H38.7845L25.0276 15.9945C24.4475 15.4144 24.4475 14.5856 25.0276 14.0055L38.9503 0Z"
            fill="#21252E"
          />
        </svg>
        <div>新闻中心</div>
      </div>
      <div class="menuBtn" @click="jumpNews">了解更多</div>
    </div>
    <div class="list flex j_s flex_wrap" v-runshow>
      <div
        class="item"
        v-for="(item, index) in showList"
        :key="index"
        @click="intoNewsDetail(item.url)"
      >
        <div class="imgView d_f_a j_c">
          <img
            class="img"
            :src="item.coverImg"
            alt=""
            referrerpolicy="no-referrer"
          />
        </div>
        <div class="contentView">
          <p class="title">{{ item.title }}</p>
          <p class="digest">{{ item.digest }}</p>
          <p class="time">{{ item.time }}</p>
          <p class="tip">查看详情</p>
        </div>
      </div>
      <div class="resizeBox"></div>
    </div>
    <!-- <div class="pages d_f_a j_c_c">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="pageNum"
        @current-change="changePage"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import newsData from "../../../public/News.json";
export default {
  data() {
    return {
      newsList: [],
      showList: [],
      pageNum: 1,
      total: 30,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.setNewsData();
  },
  methods: {
    getNewsList() {
      this.$axios
        .post("http://122.96.151.110:9875/wxPublish/getPublishList", {
          offset: 0,
          count: 20,
          no_content: 0,
        })
        .then((r) => {
          let response = r.data.item;
          let list = [];
          response.forEach((item) => {
            let obj = {};
            obj.title = item.content.news_item[0].title;
            obj.digest = item.content.news_item[0].digest;
            obj.time = this.$tools.transformTime(item.update_time);
            console.log(obj.time);
            obj.url = item.content.news_item[0].url;
            obj.coverImg = item.content.news_item[0].thumb_url;
            list.push(obj);
          });

          console.log(list);
        });
    },
    setNewsData() {
      let list = newsData.list;
      list.sort((obj1, obj2) => {
        return new Date(obj2.time).getTime() - new Date(obj1.time).getTime();
      });
      this.total = list.length;
      this.newsList = list;
      this.showList = this.newsList.slice(0, 6);
    },
    jumpNews() {
      this.$router.push("/NewsCenter");
    },

    intoNewsDetail(url) {
      window.open(url, "_bank");
    },
  },
};
</script>

<style scoped lang="scss">
.NewsListView {
  padding: 50px 1.9rem;
  background-color: #f3f3f3;
  .is_left_label {
    font-size: 0.28rem;
    margin-bottom: 0.4rem;
  }
  .is_left_label svg {
    width: 0.28rem !important;
    height: 0.28rem !important;
  }
  .is_left_label div {
    margin-left: 10px;
  }
  .menuBtn {
    font-size: 0.18rem;
    color: #666;
    cursor: pointer;
  }
  .menuBtn:hover {
    color: #1aa858;
  }
  .list {
    .resizeBox {
      width: 32.2%;
      height: 0;
    }
    .item {
      padding-bottom: 0.26rem;
      position: relative;
      width: 32.2%;
      border-radius: 0.08rem;
      overflow: hidden;
      // padding: 16px;
      background-color: #fff;
      // margin-right: 0.16rem;
      margin-bottom: 0.2rem;
      box-sizing: border-box;
    }
    .item:nth-child(3n) {
      margin-right: 0;
    }
    .imgView {
      border-radius: 0.08rem;
      overflow: hidden;
      width: 100%;
      height: 2.4rem;
      font-size: 0;
      .img {
        width: 100%;
      }
      .img:hover {
        transform: scale(1.1);
      }
    }
    .contentView {
      padding: 0.26rem 0.32rem;
      cursor: pointer;
      .title {
        font-size: 0.22rem;

        color: #333;
      }
      .time {
        font-size: 0.14rem;
        color: #999;
      }
      .digest {
        margin: 0.25rem 0;
        font-size: 0.18rem;
        color: #666;
        line-height: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .tip {
        position: absolute;
        bottom: 0.26rem;
        width: 100%;
        right: 0.32rem;
        text-align: right;
        color: #666;
      }
    }
    .contentView:hover {
      .title,
      .time,
      .digest,
      .tip {
        color: rgb(68, 124, 198);
      }
    }
  }
  .pages {
    padding: 0.8rem 1.9rem;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #1aa858;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #1aa858;
    }
    /deep/ .active {
      color: white !important;
    }
  }
}
@media screen and (max-width: 1024px) {
  .NewsListView {
    padding: 20px;

    .is_left_label {
      font-size: 0.36rem;
      line-height: 0.5rem;
      margin-top: 0.6rem;
    }
    .is_left_label svg {
      width: 0.36rem !important;
      height: 0.36rem !important;
    }
    .menuBtn {
      font-size: 0.28rem;
      color: #666;
      cursor: pointer;
    }
    .menuBtn:hover {
      color: #1aa858;
    }
    .list {
      .resizeBox {
        display: none;
      }
      .item {
        padding-bottom: 0.26rem;
        position: relative;
        width: 100%;
        border-radius: 0;
        overflow: hidden;
        // padding: 16px;
        background-color: #fff;
        margin-right: 0px;
        margin-bottom: 20px;
        box-sizing: border-box;
      }
      .item:nth-child(3n) {
        margin-right: 0;
      }
      .imgView {
        margin-bottom: 20px;
        border-radius: 0;
        overflow: hidden;
        width: 100%;
        height: 3.3rem;
        .img {
          width: 100%;
        }
        .img:hover {
          transform: scale(1.1);
        }
      }
      .contentView {
        padding: 0.26rem 0.32rem;
        cursor: pointer;
        .title {
          font-size: 0.32rem;

          color: #333;
        }
        .time {
          font-size: 0.22rem;
          color: #999;
        }
        .digest {
          margin: 0.25rem 0;
          font-size: 0.28rem;
          color: #666;
          line-height: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .tip {
          position: absolute;
          bottom: 0.26rem;
          width: 100%;
          right: 0.32rem;
          text-align: right;
          color: #666;
        }
      }
      .contentView:hover {
        .title,
        .time,
        .digest,
        .tip {
          color: rgb(68, 124, 198);
        }
      }
    }
  }
}
</style>
